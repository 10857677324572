<template>
    <div class="cardBind">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input-pattern keyBoard pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div>  
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Bestech_Card_KindName"  readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text"  :value="memberInfo?.MemberName"  readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Mobile" maxlength="10" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Birthday" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" :value="{0:'男',1:'女'}[memberInfo?.Gender]" readonly/></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint"  readonly/>
                        <p class="describe"></p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                        <p class="describe"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="title">绑定新卡信息</div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input">
                        <input-pattern keyBoard pattern="phone" v-model="mobile" :maxlength="11"></input-pattern>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">验证码：</div>
                    <div class="from-input">
                        <div class="code"><input-pattern keyBoard pattern="code" type="text" placeholder="请输入验证码" v-model="verifyCode" :maxlength="6"/></div>
                        <div class="send-code loop-code" v-if="isCodeTime">{{codeTime}}秒</div>    
                        <div class="send-code" @click="readCode()" v-else>验证码</div> 
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="clickBind()">实体卡绑定会员卡</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'
/**绑卡 */
export default {
    name:'cardBind',
    components:{ticketModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**手机号 */
            mobile:'',
            /**验证码 */
            verifyCode:'',
            /**倒计时 */
            codeTime:60,
            time:undefined,
            /**验证码是否倒计时 */
            isCodeTime:false
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        }
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN
            }).then((data)=>{
                loading.close();
                console.log('会员信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null,
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
            })
        },
        /**倒计时 */
        doLoop(){
            this.codeTime=60;
            this.isCodeTime=true;
            if(this.time) clearInterval(this.time);
            this.time=setInterval(() => {
                if(this.codeTime>0){
                    this.codeTime--;
                }else{
                    clearInterval(this.time);
                    this.isCodeTime=false;
                }
            }, 1000);
        },
        /**发送验证码 */
        readCode(){
            const  reg = /^1[0-9]{10}$/
            if(this.mobile.length<10 || !reg.test(this.mobile)){
                this.$message.error('请输入正确的手机号');
                return
            }
            const loading = this.$loading({
                text: "发送验证码中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SendMobileSMSCode",{
                App_Type:1,//1：卡绑定   2：实体卡开卡验证码  3：修改手机号验证   4：卡解绑验证
                Mobile:this.mobile,
                Verify_Type:0,
                Verify_Code:''
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.doLoop();
                    this.$message.success("验证码发送成功,请注意查收");
                }else{
                    this.$message.error("验证码发送失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('验证码发送失败：'+e);
                console.log('验证码发送失败：'+e);
            })
        },
        /**绑卡 */
        clickBind(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }
            if(!this.mobile){
                 this.$message.warning('请输入手机号');
                 return
            }
            if(!this.verifyCode){
                 this.$message.warning('请输入验证码');
                 return
            }

            this.$confirm('您确定将实体卡['+this.cardNo+']绑定到手机号为'+this.mobile+'的电子会员名下吗？', "提示", {
                confirmButtonText: "确定",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.cardBind();
                    }
                },
            });
        },
        cardBind(){
            const loading = this.$loading({
                text: "绑卡操作中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.CardBind",{
                User_ID:userInfo?.User_ID,
                Card_No:this.cardNo,//外卡号
                Mobile:this.mobile,//手机号
                Rpt_Date:userInfo?.Rpt_Date,//营业日期
                BindCard_Czy:userInfo?.Login_Name,//绑卡操作员
                BindCard_PosName:userInfo?.Department_Name, //绑卡站点
                SMS_CheckCode:this.verifyCode//短信验证码
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("绑卡操作失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("绑卡操作完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error('绑卡操作失败：'+e);
                console.log('绑卡操作失败：'+e);
            })
        }
    },
    beforeUnmount(){
       if(this.time) clearInterval(this.time);
    }
}
</script>

<style lang="scss">
@import "./cardBind.scss";
</style>